import React from "react"

import Layout from "../components/layout"
import CommonDedicatedTeam from "../components/dedicated-team/CommonDedicatedTeam"

const DedicatedTeamReact = () => {
  return (
    <Layout pageTitle="Dedicated React Developers">
      {({
        width,
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => (
        <CommonDedicatedTeam
          type="React JS/Native"
          screenWidth={width}
          handleOpenConfirmation={handleOpenConfirmation}
          isOpenConfirmation={isOpenConfirmation}
          setConfirmationOption={setConfirmationOption}
        />
      )}
    </Layout>
  )
}

export default DedicatedTeamReact
